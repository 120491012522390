import React, { useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import Dom from '@myalbum/dom';
import menuStyle from './style';
import Underlay from './Underlay';
import ReactDOM from 'react-dom';
import MenuStore from './store';
import MenuContext from './context';
import useIsFrontMost from 'hooks/useIsFrontMost';
import { observer } from 'mobx-react';
import { action, runInAction } from 'mobx';

type PortalProps = {
  roundedBorder?: [boolean, boolean, boolean, boolean],
  isOpen: boolean,
  isSubmenu?: boolean,
  position: {
    ref: any,
    event?: MouseEvent,
    hAlign: "auto"|"left"|"right"|"center"|"auto-outside"|"left-outside"|"right-outside",
    vAlign: 'auto' | 'top' | 'bottom',
    x?: number,
    y?: number,
  },
  onRequestClose: () => void,
  closeOnOutside: boolean,
  maxWidth?: number,
  isLarge?: boolean,
  style?: React.CSSProperties,
  onHover?: () => void,
}

function Portal(props:React.PropsWithChildren<PortalProps>) {
  const menuRef = useRef(null);
  const [menuPosition, _setMenuPosition] = useState({left: props.position.x, top: props.position.y});
  const [isReady, setIsReady] = useState(false);
  const store = useRef(new MenuStore({onRequestClose: props.onRequestClose})).current;
  const isFrontMostModal = useIsFrontMost();
  const childRefs = useRef([]);

  const onMenuUp = useCallback(action("MenuDown", () => { // eslint-disable-line react-hooks/exhaustive-deps
    getNextFocusChild(store.focusIndex+1);
  }), []);

  const onMenuDown = useCallback(action("MenuUp", () => { // eslint-disable-line react-hooks/exhaustive-deps
    const nextIndex = (store.focusIndex===-1) ? childRefs.current.length-1 : store.focusIndex-1;
    getPrevFocusChild(nextIndex);
  }), []);

  const onMenuEnter = useCallback(action("MenuEnter", () => { // eslint-disable-line react-hooks/exhaustive-deps
    const child = store.focusIndex>-1 ? childRefs.current[store.focusIndex] : undefined;
    if(child)
    {
      // met animationframe, want react triggert helaas ook nog een onKeyUp event na het native event
      // ook al doe je een preventDefault
      requestAnimationFrame(child.onClick);
    }
  }), []);

  useEffect(() => {
    if(!isFrontMostModal)
      return;
    
    const onKeyDown = (e:KeyboardEvent) => {
      e.preventDefault();

      switch(e.keyCode)
      {
        case 40:
          // down
          onMenuUp();
        break;
        case 38:
          // up
          onMenuDown();
        break;
      }
    }

    const onKeyUp = (e:KeyboardEvent) => {
      e.preventDefault();
      switch(e.keyCode)
      {
        case 13:
          //enter
          onMenuEnter();
        break;
        case 27:
          props.onRequestClose();
        break;
      }
    }

    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keydown", onKeyUp);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keydown", onKeyUp);
    }
  }, [isFrontMostModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const getNextFocusChild = useCallback((focusIndex:number) => {
    for(let a=focusIndex; a<childRefs.current.length; a++) {      
      const child = childRefs.current[a];
      if(child && child.isInteractive) {
        runInAction(() => {
          store.focusIndex = a;
        });
        break;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPrevFocusChild = useCallback((focusIndex:number) => {
    for(let a=focusIndex; a>=0; a--) {      
      const child = childRefs.current[a];
      if(child && child.isInteractive) {
        runInAction(() => {
          store.focusIndex = a;
        });
        break;
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMouseOver = useCallback(() => {
    store.trigger('hover');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    store.on('hover', () => {
      props.onHover();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // voorkomt dat menu buiten window valt en stelt daarna de positie in
  const setMenuPosition = useCallback((position:Position) => {
    const bodyDom = Dom(document.body);
    const menuDom = Dom(menuRef.current);
    const windowLayout = bodyDom.layout();
    const menuLayout = menuDom.layout();
    const windowHeight = menuDom.isFixed() ? window.innerHeight : windowLayout.height;

    const fixedPos:any = {...position};
    if(position.left!==undefined)
    {
      if(position.left + menuLayout.width > windowLayout.width)
        fixedPos.left = windowLayout.width - menuLayout.width;
      if(position.left < 0)
        fixedPos.left = 0;

      fixedPos.right = 'auto';
    } else if(position.right !== undefined) {
      fixedPos.left = 'auto';

      if(position.right < 0)
        fixedPos.right = 0;
      else if(position.right + menuLayout.width > windowLayout.width) {
        fixedPos.right = windowLayout.width - menuLayout.width - 5;
      }
    }

    if(position.top<0)
      fixedPos.top = 0;
    
    if(position.top > (windowHeight - menuLayout.height - 10))
      fixedPos.top = windowHeight - menuLayout.height - 10;

    _setMenuPosition(fixedPos);
  }, []);

  const onClickOutside = useCallback((e:MouseEvent) => {
    if(e.clientY<=65 && props.closeOnOutside)
    {
      props.onRequestClose();
    }
  }, [props.closeOnOutside]); // eslint-disable-line react-hooks/exhaustive-deps


  // BORDER RADIUS
  const classes = ['ui-menu'];
  const [topLeft, topRight, bottomRight, bottomLeft] = props.roundedBorder;
  if(topLeft) classes.push('radius-top-left');
  if(topRight) classes.push('radius-top-right');
  if(bottomRight) classes.push('radius-bottom-right');
  if(bottomLeft) classes.push('radius-bottom-left');
  if(props.isLarge) classes.push('is-large');

  useLayoutEffect(() => {
    if(!menuRef.current)
      return;
    
    if(props.position.ref?.current && props.isOpen) {
      setMenuPosition(getRelativePosition(props.position.ref.current, menuRef.current, props.position.hAlign, props.position.vAlign, {x: props.position.x, y: props.position.y}));
      setIsReady(true);
    } else if(props.position.event?.clientX && props.position.event?.clientY && props.isOpen) {
      setMenuPosition({
        ...props.position,
        left: props.position.event.clientX,
        top: props.position.event.clientY,
      });
      setIsReady(true);
    } else if (props.isOpen) {
      setMenuPosition({
        left: props.position.x,
        top: props.position.y,
      });
      setIsReady(true);
    }

    const _menuRef = menuRef.current;
    if(props.isOpen)
    {
      _menuRef.addEventListener('wheel', preventDefault);
      window.addEventListener('touchstart', onClickOutside);
      window.addEventListener('mousedown', onClickOutside);
      window.addEventListener('scroll', props.onRequestClose);
    }

    return () => {
      _menuRef.removeEventListener('wheel', preventDefault);
      window.removeEventListener('touchstart', onClickOutside);
      window.removeEventListener('mousedown', onClickOutside);
      window.removeEventListener('scroll', props.onRequestClose);
    }

  }, [props.isOpen, menuRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
  

  // Portal nodig om zIndex te fixen, anders kan je het menu bv niet onder de menubar zetten
  return ReactDOM.createPortal((
    <MenuContext.Provider value={store}>

      <div className="menu-holder">
        {!props.isSubmenu && isReady && props.isOpen && <Underlay onClick={props.onRequestClose} />}

        <div
          className="ui-menu-wrapper"
          style={{
            top: menuPosition.top,
            overflow: !topLeft && !topRight ? 'hidden' : 'visible',
          }}
        >
          <div
            className={classes.join(' ')}
            ref={menuRef}
            style={{
              ...{...menuPosition, top: 0},
              maxWidth: props.maxWidth!==undefined ? props.maxWidth : 'auto',
              visibility: isReady ? 'visible' : 'hidden',
              ...props.style,
            }}
            onMouseOver={onMouseOver}
          >
            {
              React.Children.map(props.children, (child:any, index) => {
                if(child)
                {
                  return React.cloneElement(child, {
                    index: index,
                    key: "menu-item-" + index,
                    ref: (ref:any) => {
                      childRefs.current[index] = ref
                    },
                  })
                }
              })
            }
          </div>
        </div>

        <style jsx>{menuStyle}</style>
      </div>
    </MenuContext.Provider>
  ), document.getElementById('root') || document.body);
}

export default observer(Portal);

function preventDefault(e) {
  e.preventDefault();
  e.stopPropagation();
}

function getRelativePosition(node:HTMLElement, menuNode:HTMLElement, hAlign:"auto"|"left"|"right"|"center"|"left-outside"|"right-outside"|"auto-outside" , vAlign:"bottom"|"top"|"auto", offset:{x:number, y:number}):Position {
  const _position:Position = {};
  let window = Dom(document.body).layout();
  let menu = Dom(menuNode).layout();
  let nodeRect = node.getBoundingClientRect();

  if(isNaN(offset.x)) {
    offset.x = 0;
  }
  if(isNaN(offset.y)) {
    offset.y = 0;
  }

  switch(hAlign) {
    case "auto":
      if(nodeRect.left + offset.x + menu.width > window.width)
      {
        _position.right = nodeRect.left + nodeRect.width + offset.x;
      }else
      {
        _position.left = nodeRect.left + offset.x;
      }
    break;
    case "left":
      _position.left = nodeRect.left + offset.x;
    break;
    case "right":
      _position.left = nodeRect.left + nodeRect.width + offset.x - menu.width;
    break;
    case "center":
      _position.left = nodeRect.left + (nodeRect.width / 2) - (menu.width / 2) + offset.x;
    break;
    case "left-outside":
      _position.left = nodeRect.left - menu.width + offset.x;
    break;
    case "right-outside":
      _position.left = nodeRect.left + nodeRect.width + offset.x;
    break;
    case "auto-outside":
      if(nodeRect.left + offset.x + nodeRect.width + menu.width < window.width) {
        _position.left = nodeRect.left + offset.x + nodeRect.width;
      } else {
        _position.right = (window.width - (nodeRect.left - offset.x));
      }
    break;
  }

  if(vAlign === "bottom") {
    _position.top = nodeRect.top + nodeRect.height + offset.y;
  } else {
    _position.top = nodeRect.top + offset.y;
  }

  return _position;
}