import { forwardRef } from "react";
import { separatorStyle } from "../style";

const Separator = forwardRef(() => {
  return (
    <div className="separator">
      <style jsx>{separatorStyle}</style>
    </div>
  );
});

Separator.displayName = "Separator";

export default Separator;