import {buttonStyle} from '../style';
import { forwardRef, useCallback, useContext, useImperativeHandle } from 'react';
import MenuContext from "../context";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.fullWidth
 * @param {function} props.onClick
 */
const MenuButton = forwardRef(({title, fullWidth, onClick, className}, ref) => {
  const classes = ["button padding "+className];

  if(fullWidth===true)
    classes.push('full-width');

  const store = useContext(MenuContext);
  const clickHandler = useCallback(() => {
    store.requestClose();
    onClick();
  }, [onClick]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    isInteractive: true,
    onClick: clickHandler,
  }));

  return (
    <div
      className={classes.join(' ' )}
      onClick={clickHandler}
    >
      <div className="inner">{title}</div>
      <style jsx>{buttonStyle}</style>
    </div>
  );
});

MenuButton.defaultProps = {
  fullWidth: true,
  className: '',
}

MenuButton.displayName = 'MenuButton';
export default MenuButton;