import css from 'styled-jsx/css'

export default css`
  .menu-holder {
    position: fixed;
    z-index: 99999999;
  }

  .ui-menu-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    pointer-events: none;
  }

  .ui-menu {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    background-color: rgba(var(--menuBg), 1);
    border: 0.5px solid rgba(var(--border1), 1);
    padding-bottom: 5px;
    padding-top: 5px;
    box-shadow:
    0 4.1px 8px rgba(0, 0, 0, 0.08),
    0 4.3px 7.6px rgba(0, 0, 0, 0.028),
    0 0.5px 12.6px rgba(0, 0, 0, 0.035),
    0 0.9px 25.2px rgba(0, 0, 0, 0.042),
    0 1.7px 47px rgba(0, 0, 0, 0.05),
    0 4px 80px rgba(0, 0, 0, 0.07)
    ;
    pointer-events: auto;
  }

  .ui-menu.radius-top-left {
    border-top-left-radius: 6px;
  }
  .ui-menu.radius-top-right {
    border-top-right-radius: 6px;
  }
  .ui-menu.radius-bottom-right {
    border-bottom-right-radius: 6px;
  }
  .ui-menu.radius-bottom-left {
    border-bottom-left-radius: 6px;
  }

  .ui-menu.is-large {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ui-menu:not(.is-large) :global(.padding) {
    padding: 8px 20px;
  }
  .ui-menu.is-large :global(.padding) {
    padding: 8px 30px;
  }
`;

export const underlayStyle = css`
  .underlay {
    top: 65px;
    left: 0px;
    right: 0;
    height: 100vh;
    position: fixed;
  }
`

export const itemStyle = css`
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: inherit;
    color: rgba(var(--menuColor), 1);
    white-space: nowrap;
    user-select: none;
    position: relative;
    font-size: 16px;
    text-decoration: none;
  }

  .item.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .item.interactive {
    cursor: pointer;
  }

  .item.interactive.has-focus {
    background-color: rgba(var(--menuHoverBg));
    color: rgba(var(--menuHoverColor), 1) !important;
  }

  .item .title {
    flex: 1;
    padding-right: 50px;
  }

  .item.interactive.has-focus :global(.balloon) {
    background-color: rgba(var(--menuHoverColor), 1) !important;
    color: rgba(var(--menuHoverBg), 1) !important;
  }

  .item.destructive {
    color: rgba(var(--redColor), 1);
  }
  .item.destructive.interactive.has-focus {
    background-color: rgba(var(--redColor));
    color: rgba(var(--menuHoverColor), 1) !important;
  }
`

export const iconSpaceStyle = css`
  .icon-space {
    width: 20px;
    height: 100%;
    margin-right: 5px;
    display: inline-block;
    flex-shrink: 0;
  }

  .icon-space :global(svg) {
    margin-bottom: -1px;
    margin-top: 3px;
  }
`

export const textBlockStyle = css`
  .text-block {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    background-color: inherit;
    color: rgba(var(--menuColor), 1);
    user-select: none;
    position: relative;
    font-size: 15px;
    text-decoration: none;
    margin-top: 5px;
  }

  .text-block .inner {
    flex: 1;
  }

  .text-block .title {
    font-size: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }
  .text-block .title-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-block .text {
    font-size: 15px;
    margin-top: 2px;
  }

  .text-block .more {
    border-radius: 30px;
    width: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
    cursor: pointer;
    margin-left: 15px;
  }
  .text-block .more:hover {
    background-color: rgba(var(--accentColor), 0.07);
  }
`

export const separatorStyle = css`
.separator {
  height: 1px;
  background-color: rgba(var(--border1),1);
  margin-top: 5px;
  margin-bottom: 5px;
}
`

export const titleStyle = css`
.title {
  font-size: 11px;
  opacity: 0.64;
  color: rgba(var(--menuColor), 1);
  display: flex;
  flex-direction: row;
}
`

export const checkStyle = css`
  .check {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    color: rgba(var(--menuColor), 1);
    cursor: pointer;
  }
  .check.has-focus {
    background-color: rgba(var(--accentColor), 1);
    color: rgba(var(--menuHoverColor), 1) !important;
  }
  .check .title {
    font-size: 16px;
  }
  .check .text {
    font-size: 12px;
  }
`;

export const submenuStyle = css`
  .submenu {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    color: rgba(var(--menuColor), 1);
    cursor: pointer;
  }

  .submenu .title {
    flex: 1;
  }

  .submenu.is-open {
    background-color: rgba(var(--menuHoverBg), 0.7);
    color: rgba(var(--menuHoverColor), 1) !important;
  }

  .submenu.has-focus {
    background-color: rgba(var(--menuHoverBg));
    color: rgba(var(--menuHoverColor), 1) !important;
  }
`;

export const buttonStyle = css`
  .button {
    display: flex;
    flex-direction: row;
    text-decoration: none;
  }

  .button .inner {
    border: 1px solid rgba(var(--accentColor),1);
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
    color: rgba(var(--accentColor),1);
    font-size: 14px;
  }
  .button.full-width .inner {
    flex: 1;
    text-align: center;
  }
  .button .inner:hover {
    background-color: rgba(var(--menuHoverBg),1);
    color: rgba(var(--menuHoverColor),1);
  }
  .button.accent .inner {
    background-color:  rgba(var(--menuHoverBg),1);
    color: rgba(var(--menuHoverColor),1);
    line-height: 25px;
  }
`;

export const notificationStyle = css`
  .notification {
    font-size: 15px;
    color: rgba(var(--menuColor), 1);
    display: flex;
    flex: 1;
    flex-direction: row;
    cursor: pointer;
    align-items: flex-start;
  }
  .notification .title {
    font-size: 16px;
  }
  
  .notification:hover {
    background-color: rgba(var(--menuHoverBg),1);
    color: rgba(var(--menuHoverColor), 1);
  }
  .notification:hover .content {
    opacity: 1;
  }
  .notification:hover .content .title {
    opacity: 1;
  }
`;

export const balloonStyle = css`
  .balloon {
    border-radius: 100px;
    font-size: 10px;
    min-width: 20px;
    height: 20px;
    overflow: hidden;
    color: white;
    font-family: arial;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
`;