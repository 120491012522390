import { useContext, useCallback, forwardRef, useImperativeHandle } from 'react';
import MenuContext from "../context";
import IconSpace from "./IconSpace";
import {itemStyle, balloonStyle} from "../style";
import { observer } from 'mobx-react';

type ItemProps = {
  title: string,
  icon?: any,
  disabled?: boolean,
  onClick?: () => void,
  style?: React.CSSProperties,
  destructive?: boolean,
  balloon?: {
    value: number,
    bg: [number, number, number, number],
    color: [number, number, number, number],
  },
  index?: number,
}

const Item = forwardRef((props:ItemProps, ref) => {
  const {title, icon, disabled, onClick, style, balloon, index, destructive} = props;
  const store = useContext(MenuContext);
  const classNames = ["item padding"];
  
  if(disabled) {
    classNames.push("disabled");
  }

  if(onClick !== undefined) {
    classNames.push("interactive");
  }

  if(store.focusIndex === index) {
    classNames.push('has-focus');
  }

  if(destructive) {
    classNames.push('destructive');
  }

  const _onClick = useCallback(() => {
    if(onClick) {
      store.requestClose();
      onClick();
    }
  }, [onClick]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    isInteractive: !disabled,
    onClick: _onClick,
  }));

  const onMouseEnter = useCallback(() => {
    store.focusIndex = index;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMouseLeave = useCallback(() => {
    store.focusIndex = -1;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      onClick={_onClick}
      style={style}
      className={classNames.join(" ")}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <IconSpace icon={icon} />
      <div className="title">
        {title}
      </div>
      {balloon?.value>0 && <Balloon {...balloon} />}
      <style jsx>{itemStyle}</style>
    </div>
  );
});

Item.defaultProps = {
  icon: null,
  disabled: false,
  balloon: undefined,
  style: undefined,
}
Item.displayName = 'Item'

export default observer(Item);


function Balloon({value, bg, color}) {
  return (
    <div
      className="balloon"
      style={{
        backgroundColor: `rgba(${bg}, 1)`,
        color: `rgba(${color}, 1)`,
      }}
    >
      {isNaN(value) ? value : value > 9 ? "9+": value}
      <style jsx>{balloonStyle}</style>
    </div>
  )
}
