import {notificationStyle} from '../style';
import IconSpace from './IconSpace';
import UINotification from "components/Notifications/Notification/Notification";
import { useCallback, useContext } from 'react';
import MenuContext from "../context";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.text
 * @param {string} props.time
 * @param {object} props.avatar
 * @param {function} props.onClick
 * @param {boolean} props.isRead
 */
function Notification({title, text, time, avatar, onClick, isRead}) {
  const store = useContext(MenuContext);
  const clickHandler = useCallback(() => {
    store.requestClose();
    onClick();
  }, [onClick]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = ['notification', 'padding'];
  
  return (
    <div className={classes.join(' ')} onClick={clickHandler}>
      <IconSpace />

      <UINotification
        avatar={avatar}
        title={title}
        text={text}
        time={time}
        isRead={isRead}
      />

      <style jsx>{notificationStyle}</style>
    </div>
  );
}

Notification.defaultProps = {
  avatar: undefined,
  onClick: undefined,
  time: '',
  isRead: false,
}

export default Notification;