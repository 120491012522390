import { useRef, useState } from 'react';
import MenuContext from './context';
import Item from "./items/Item";
import IconSpace from "./items/IconSpace";
import TextBlock from "./items/TextBlock";
import Separator from './items/Separator';
import Title from './items/Title';
import Check from './items/Check';
import Submenu from './items/Submenu';
import Button from './items/Button';
import Notification from './items/Notification';
import Link from './items/Link';
import ArrayItems from './ArrayItems';
import MenuPortal from './MenuPortal';

const isServerSide = typeof window === "undefined";

type MenuProps = {
  isOpen: boolean,
  onRequestClose: () => void,
  closeOnOutside: boolean,
  style?: React.CSSProperties,
  maxWidth?: number,
  position: {
    ref: any,
    event?: MouseEvent,
    hAlign: "auto"|"left"|"right"|"center"|"auto-outside"|"left-outside"|"right-outside",
    vAlign: 'auto' | 'top' | 'bottom',
    x?: number,
    y?: number,
  },
  isLarge?: boolean,
  isSubmenu?: boolean,
  roundedBorder?: [boolean, boolean, boolean, boolean],
  onHover?: () => void,
}



export default function Menu(props:React.PropsWithChildren<MenuProps>) {  
  if(isServerSide)
    return null;

  if(!props.isOpen || !props.children)
    return null;

  return <MenuPortal {...props} />
}

Menu.defaultProps = {
  roundedBorder: [true, true, true, true],
  position: {
    ref: false,
    hAlign: 'auto',
    vAlign: 'auto',
    x: 0,
    y: 0,
  },
  onRequestClose: () => {},
  closeOnOutside: true,
  onHover: () => {},
}

Menu.ArrayItems = ArrayItems;
Menu.Button = Button;
Menu.Check = Check;
Menu.Context = MenuContext;
Menu.IconSpace = IconSpace;
Menu.Title = Title;
Menu.Link = Link;
Menu.Notification = Notification;
Menu.Separator = Separator;
Menu.Submenu = Submenu;
Menu.Item = Item;
Menu.TextBlock = TextBlock;

Menu.useOpener = () => {
  const [isOpen, _setIsOpen] = useState(false);
  const lastShowMenuAction = useRef(null);

  function setIsOpen(state) {
    const now = new Date().getTime();
    if(!state) 
    {
      if(lastShowMenuAction.current===null) {
        _setIsOpen(state);
      }else
      {
        const diff = now - lastShowMenuAction.current;
        if(diff>100)
        {
          _setIsOpen(state);
        }
      }
    }else
    {
      _setIsOpen(state);
    }

    lastShowMenuAction.current = now;
  }

  return [
    isOpen, setIsOpen
  ]
}