import Avatar from "components/Avatar/Avatar";
import { timeSince } from "utils/Utils";
import style from "./style";

export default function Notification({avatar, title, text, time, isRead}) {
  const classes = ['notification'];
  if(isRead) classes.push('is-read');

  return (
    <div
      className={classes.join(" ")}
    >
      {avatar && (<div className="avatar"><Avatar size={40} {...avatar} /></div>)}
      <div className="content">
        <div className="title">{title}</div>
        <div className="text">{text}</div>
        <div className="time">{timeSince(time)}</div>
      </div>

      <style jsx>{style}</style>
    </div>
  );

}