import {useCallback, useState, useRef, useEffect, useContext, forwardRef, useImperativeHandle} from 'react';
import {submenuStyle} from '../style';
import IconSpace from './IconSpace';
import Menu from '../Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight as chevronIcon } from "@fortawesome/pro-light-svg-icons/faChevronRight";
import MenuContext from "../context";
import { observer } from 'mobx-react';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {number} props.maxWidth
 * @param {object} props.style
 */
const Submenu = forwardRef(({title, maxWidth, icon, index, style, ...props}, ref) => {
  const myref = useRef(null);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const showTimer = useRef(null);
  const hideTimer = useRef(null);
  const store = useContext(MenuContext);
  const onClick = useCallback(() => {
    setShowSubmenu(true);
  }, []);

  const onMouseOver = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onMouseEnter = useCallback(() => {
    showAfterDelay();
    store.focusIndex = index;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showAfterDelay = useCallback(() => {
    cancelDelayedShow();
    cancelDelayedHide();
    store.trigger('hover');

    showTimer.current = setTimeout(() => {
      setShowSubmenu(true);
    }, 300);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  const cancelDelayedShow = useCallback(() => {
    if(showTimer.current)
      clearTimeout(showTimer.current);
  }, []);

  const hideAfterDelay = useCallback(() => {
    cancelDelayedShow();
    cancelDelayedHide();

    hideTimer.current = setTimeout(() => {
      setShowSubmenu(false);
    }, 300);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const cancelDelayedHide = useCallback(() => {
    if(hideTimer.current)
      clearTimeout(hideTimer.current);
  }, []);

  const onMouseLeave = useCallback(() => {
    cancelDelayedShow();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onSubmenuHover = useCallback(() => {
    cancelDelayedHide();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRequestClose = useCallback(() => {
    setShowSubmenu(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    store.on('hover', () => {
      hideAfterDelay();
      cancelDelayedShow();
    });

    return () => {
      store.off('hover');
      cancelDelayedHide();
      cancelDelayedShow();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    isInteractive: true,
    onClick: () => {
      setShowSubmenu(true);
    }
  }));

  const classNames = ['submenu padding'];
  if(showSubmenu)
    classNames.push("is-open");

  if(store.focusIndex === index) {
    classNames.push('has-focus');
  }

  return (
    <div
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      className={classNames.join(" ")}
      ref={myref}
    >
      <IconSpace icon={icon} />
      <div className="title">
        {title}
      </div>
      <div className="chevron">
        <FontAwesomeIcon icon={chevronIcon} size="xs" />
      </div>

      <Menu
        position={{
          ref: myref,
          hAlign: "auto-outside",
          vAlign: "top",
          x: -3,
          y: -5,
        }}
        isOpen={showSubmenu}
        isSubmenu={true}
        onHover={onSubmenuHover}
        maxWidth={maxWidth}
        onRequestClose={onRequestClose}
      >
        {props.children}
      </Menu>

      <style jsx>{submenuStyle}</style>
    </div>
  );
});

Submenu.defaultProps = {
  maxWidth: undefined,
  style: undefined,
  icon: undefined,
}

Submenu.displayName = 'Submenu';

export default observer(Submenu);