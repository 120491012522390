import {buttonStyle} from '../style';
import IconSpace from './IconSpace';
import Link from 'next/link';
import { forwardRef } from 'react';

/**
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.fullWidth
 * @param {string} props.href
 * @param {string} [props.as]
 */
const MenuLink = forwardRef(({title, fullWidth, href, as, className}, ref) => {
  const classes = ["button padding "+className];

  if(fullWidth===true) classes.push('full-width');

  return (
    <Link href={href} as={as} legacyBehavior>
      <a
        className={classes.join(' ' )}
        ref={ref}
      >
        <IconSpace />
        <div className="inner">{title}</div>
        <style jsx>{buttonStyle}</style>
      </a>
    </Link>
  );
});
MenuLink.displayName = 'MenuLink';

MenuLink.defaultProps = {
  fullWidth: true,
  className: "",
}

export default MenuLink;