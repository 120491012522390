import { Button, RadioButton } from "components/UI";
import { Stack } from "components/UI/ModalV2/ModalV2";
import { useCallback, useEffect, useState } from "react";
import translationStore from "stores/translation-store";
import { useUser } from "utils/user/lib";
import {requestModalStyle} from './style';

function RequestModal(props) {
  const user = useUser();
  const {initialRole, request, roles, onClose} = props;
  const [selectedRole, setSelectedRole] = useState(initialRole);

  const saveRole = useCallback(() => {
    let promise;
    switch (selectedRole) {
      case 'noaccess':
        [promise] = user.FetchJSON(request.links.delete, {
          method: "DELETE",
          data: {selectedRole}
        });
      break;
      default:
        [promise] = user.FetchJSON(request.links.approve, {
          method: "POST",
          data: {role: selectedRole}
        });
      break;
    }
    
    if(promise) {
      promise.then(() => {
        onClose(props.request);
      })
    }
  }, [request, selectedRole, props.request, onClose]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function keyDown(e) {
      if(e.keyCode===13)
      {
        saveRole();
      }
    }

    window.addEventListener('keydown', keyDown);
    return () => {
      window.removeEventListener('keydown', keyDown);
    }
  }, [saveRole, selectedRole]);

  return (
    <Stack
      onClose={() => onClose(false)}
      width={400}
    >     
      <div className='content'>
        <h2>{request.data.name}</h2>
        <h3>{translationStore.translate('requests.approve-modal.text', {name: request.subject.title})}</h3>

        <div className='roles'>
          
          <div className="role">
            <RadioButton
              selected={selectedRole === "noaccess"}
              onSelect={() => setSelectedRole("noaccess")}
            >
              <div className='radio-title'>{translationStore.translate('requests.approve-modal.no-access.title')}</div>
            </RadioButton>
          </div>

          {
            roles.map(role => {
              return (
                <div className="role" key={"role-"+role}>
                  <RadioButton
                    selected={selectedRole === role}
                    onSelect={() => setSelectedRole(role)}
                  >
                    <div className='radio-title'>{translationStore.translate(`members.role.${role}`)}</div>
                    <div className='radio-description'>{translationStore.translate(`members.role.${role}.text`)}</div>
                  </RadioButton>
                </div>
              )
            })
          }
        </div>

        <Button
          className="accent large"
          style={{marginTop: 50}}
          onClick={saveRole}
        >
          {translationStore.translate("base.save")}
        </Button>

        <style jsx>{requestModalStyle}</style>
      </div>
    </Stack>
  )
}

RequestModal.defaultProps = {
  initialRole: "reader",
}

export default RequestModal;