import {useState, useRef, useCallback, forwardRef, useImperativeHandle} from 'react';
import { textBlockStyle } from "../style";
import Menu from '../Menu';
import { faEllipsisH } from "@fortawesome/pro-light-svg-icons/faEllipsisH";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.text
 * @param {number} [props.menu.maxWidth]
 */
const TextBlock = forwardRef(({title, text, menu, ...props}, ref) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const moreRef = useRef(null);

  const onRequestClose = useCallback(() => {
    setShowSubmenu(false);
  }, []);

  const showMenu = useCallback(() => {
    setShowSubmenu(true);
  }, []);

  useImperativeHandle(ref, () => ({
    isInteractive: false,
  }));

  const hasMenu = props.children;

  return (
    <div className={"text-block padding"}>
      
      <div className="inner">
        <div className="title">
          <div className="title-inner">{title}</div>
          {hasMenu && <div ref={moreRef} className="more" onClick={showMenu}><FontAwesomeIcon icon={faEllipsisH} /></div>}
        </div>
        <div className="text">{text}</div>
      </div>

      <Menu
        position={{
          ref: moreRef,
          hAlign: "center",
          vAlign: "bottom",
          x: -3,
          y: -5,
        }}
        isOpen={showSubmenu}
        maxWidth={menu?.maxWidth}
        onRequestClose={onRequestClose}
      >
        {props.children}
      </Menu>

      <style jsx>{textBlockStyle}</style>
    </div>
  );
});

TextBlock.defaultProps = {
  menu: {
    maxWidth: undefined,
  }
}

TextBlock.displayName = "TextBlock";

export default TextBlock;