import { forwardRef, useImperativeHandle } from 'react';
import {titleStyle} from '../style';
import IconSpace from './IconSpace';

type TitleProps = {
  title: string;
}

const Title = forwardRef<any, TitleProps>(({title}, ref) => {
  useImperativeHandle(ref, () => ({
    isInteractive: false,
  }));

  return (
    <div className="title padding">
      <IconSpace />
      <div>{title}</div>
      <style jsx>{titleStyle}</style>
    </div>
  );
});

Title.displayName = "Title";
export default Title;