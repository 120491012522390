type EventData = {
  callback: () => void,
  uid: string,
  once: boolean
}

class Events {
  _ons: {
    [key: string]: EventData[]
  } = {};

  on(eventName:string, callback:() => void, once:boolean = false)
  {
    let eventData = eventName.split(".");
    let type = eventData[0];
    let uid = eventData[1];

    if(this._ons[type]===undefined)
      this._ons[type] = [];

    this._ons[type].push({callback, uid, once});
  }

  off(eventName:string, callback:() => void = undefined)
  {
    let eventData = eventName.split(".");
    let type = eventData[0];
    let uid = eventData[1];

    if(this._ons[type]===undefined || this._ons[type].length===0)
      return;

    if(callback===undefined && uid===undefined)
    {
      this._ons[type] = [];
    }else
    {
      let i = 0;
      while(this._ons[type][i])
      {
        if((callback===undefined || (callback && this._ons[type][i].callback === callback)) && (uid===undefined || (uid && this._ons[type][i].uid === uid)))
        {
          this._ons[type].splice(i, 1);

          i--;
          if(i<0)
            i = 0;
        }

        i++;
      }
    }
  }

  one(type:string, callback:() => void)
  {
    this.on(type, callback, true);
  }

  trigger(type:string, ...args:any[])
  {
    if(this._ons[type]===undefined || this._ons[type].length===0)
      return;

    let max = this._ons[type].length;
    for(let i=0; i<max; i++)
    {
      let listener = this._ons[type][i];
      if(listener && listener.hasOwnProperty('callback'))
        listener.callback.apply(null, args);

      if(listener && listener.once)
      {
        this._ons[type].splice(i, 1);
        if(i < max-1)
          i--;
      }
    }
  }
}

export default Events;