import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle} from 'react';
import IconSpace from './IconSpace';
import {checkStyle} from '../style';
import MenuContext from "../context";
import { faCheck as checkIcon } from "@fortawesome/pro-light-svg-icons/faCheck";
import { observer } from 'mobx-react';

type CheckProps = {
  title: string;
  text: string;
  onClick: (value: any) => void;
  closeOnClick?: boolean;
  value: any;
  checked: boolean;
  index?: number;
}

const Check = forwardRef<any, CheckProps>(({title, text, onClick, closeOnClick, value, checked, index}, ref) => {
  const store = useContext(MenuContext);
  const icon = checked ? checkIcon : null;

  useEffect(() => {
    const _icon = store.registerIcon();
    return () => {
      _icon.destroy();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _onClick = useCallback(() => {
    if(closeOnClick) {
      store.requestClose();
    }

    onClick(value);
  }, [value, closeOnClick]); // eslint-disable-line react-hooks/exhaustive-deps

  const onMouseEnter = useCallback(() => {
    store.focusIndex = index;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onMouseLeave = useCallback(() => {
    store.focusIndex = -1;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    isInteractive: true,
    onClick: _onClick,
  }));

  const classNames = ["check", "interactive", "padding"];
  if(store.focusIndex === index) {
    classNames.push('has-focus');
  }

  return (
    <div
      className={classNames.join(" ")}
      onClick={_onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <IconSpace icon={icon} />
      <div>
        <div className="title">{title}</div>
        {text!==undefined && <div className="text">{text}</div>}
      </div>

      <style jsx>{checkStyle}</style>
    </div>
  );
});

Check.defaultProps = {
  checked: false,
  text: undefined,
  onClick: () => {},
  closeOnClick: true,
}

Check.displayName = "Check";

export default observer(Check);