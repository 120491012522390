import style from "./style";

export default function RadioButton(props)
{
  const { onSelect, selected, disabled, buttonStyle, children, ...rest } = props;

  const _onSelect = () =>
  {
    if(props.disabled)
      return;

    if(onSelect)
      onSelect.call(null, true);
  }

  return (
    <div {...rest} className={`ui-radio${selected ? " selected" : ""}${disabled ? " disabled" : ""}`} onClick={() => _onSelect()}>
      <div className="ui-radio-button" style={buttonStyle}></div>
      <div className="ui-radio-content">{children}</div>
      <style jsx>{style}</style>
    </div>
  )
}

RadioButton.defaultProps = {
  selected: false,
  disabled: false
}