import {useRef, useCallback} from 'react';
import {underlayStyle} from './style';

function Underlay(props) {
  const {className} = props;
  const ref = useRef(null);

  const onClick = useCallback(() => {
    if(props.onClick)
      props.onClick();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={ref}
      className={[className, "underlay"].join(" ")}
      onClick={onClick}
      onTouchMove={onClick}
    >
      <style jsx>{underlayStyle}</style>
    </div>
  );
}

export default Underlay;