import css from 'styled-jsx/css'

export default css`
  .container {
    width: 100%;
  }

  .all-request {
    position: relative;
    width: 100%;
  }

  .intro {
    padding-left: 40px;
    padding-right: 40px;
  }

  .intro h3 {
    margin-bottom: -10px;
  }

  .group-title {
    width: 100%;
    margin-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .group-title h2 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .request {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
  }
  .request:hover {
    background-color: rgba(var(--accentColor), 1);
    color: white;
  }

  .icon {
    margin-top: 8px;
    margin-bottom: 8px;
    height: 40px;
    width: 40px;
  }

  .name {
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
    min-height: 40px;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }

  .name > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-left: 10px;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
  }

  .request + .request .content:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    height: 1px;
    content: '';
    background-color: rgba(var(--accentColorLight), 1);
  }

  .request:hover .content:after {
    display: none;
  }

  .empty {
    font-size: 15px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 50px;
  }
`;

export const requestModalStyle = css`
  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .roles {
    display: flex;
    flex-direction: column;
  }

  .role + .role {
    margin-top: 10px;
  }

  .radio-description {
    font-size: 13px;
    opacity: 0.7;
  }
`;