import { Menu } from "..";

/**
 * @param {object} props
 * @param {array} props.items - Array of items, must include a type and other props must match the props of that component type
 */
function ArrayItems(items) {
  return items.map((menuItem, index) => {
    switch(menuItem?.type) {
      case 'checkmark':
        return (
          <Menu.Check
            key={`item-${index}`}
            {...menuItem}
          />
        );
      case 'item':              
        return (
          <Menu.Item
            key={`item-${index}`}
            {...menuItem}
          />
        );
      case 'button':
        return (
          <Menu.Button
            key={`item-${index}`}
            {...menuItem}
          />
        );
      case 'link':
          return (
            <Menu.Link
              key={`item-${index}`}
              {...menuItem}
            />
          );
      case 'textBlock':
        return (
          <Menu.TextBlock
            key={`item-${index}`}
            {...menuItem}
          />
        )
      case 'separator':
        return <Menu.Separator key={`item-${index}`} />
      default:
        console.warn(`Unknown menu item type: ${menuItem.type}`);
        return null;
    }
  })
}

export default ArrayItems;