import { observable, makeObservable, computed, action } from "mobx";
import Events from 'utils/Events';

type MenuStoreConfig = {
  onRequestClose?: () => {}
}

class MenuStore extends Events {
  hasIconSpacing = false;
  icons = observable<any>([]);
  config:MenuStoreConfig = {};
  _focusIndex = -1;

  constructor(config) {
    super();
    
    makeObservable(this, {
      hasIconSpacing: observable,
      icons: observable,
      _focusIndex: observable,

      hasIcons: computed,
      registerIcon: action,
      unregisterIcon: action,
      
      focusIndex: computed,
    });

    this.config = config;
  }

  get hasIcons() {
    return this.icons.length>0;
  }
  
  registerIcon() {
    const icon = new Icon(this);
    this.icons.push(icon);

    return icon;
  }

  unregisterIcon(removeItem) {
    this.icons.replace(this.icons.filter(item => item !== removeItem));
  }

  requestClose() {
    this.config.onRequestClose?.();
  }

  get focusIndex() {
    return this._focusIndex;
  }
  set focusIndex(item) {
    this._focusIndex = item;
  }
}

class Icon {
  store;
  name?:string;

  constructor(store) {
    this.store = store;
  }

  destroy() {
    this.store.unregisterIcon(this);
  }
}

export default MenuStore;